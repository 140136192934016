<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div class="outerOne">
        <div>
          <img src="@/assets/img/valuesBJ2.jpg" alt="" class="imgHoverBig" />
        </div>
        <div class="boxTwo">
          <div class="boxTitle">
            <div class="tipOne">{{ $t("missionvisionvalues.MVV_tipOne") }}</div>
            <div class="tipTwo">{{ $t("missionvisionvalues.MVV_tipTwo") }}</div>
          </div>
        </div>
        <div class="boxTwo boxThree">
          <div>
            <div class="tipOne">
              {{ $t("missionvisionvalues.MVV_tipThree") }}
            </div>
            <div class="tipTwo">
              {{ $t("missionvisionvalues.MVV_tipFour") }}
            </div>
          </div>
        </div>
        <div>
          <img src="@/assets/img/valuesBJ1.jpg" alt="" class="imgHoverBig" />
        </div>
      </div>

      <div class="outerTwo">
        <div class="tipFive">{{ $t("missionvisionvalues.MVV_tipFive") }}</div>
        <div class="imgList">
          <div>
            <img src="@/assets/img/mvv_aaa.png" alt="" class="imgHoverBig" />
          </div>
          <div>
            <img src="@/assets/img/mvv_bbb.png" alt="" class="imgHoverBig" />
          </div>
          <div>
            <img src="@/assets/img/mvv_ccc.png" alt="" class="imgHoverBig" />
          </div>
          <div>
            <img src="@/assets/img/mvv_ddd.png" alt="" class="imgHoverBig" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：关于我们-使命愿景价值观
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-12 16:43
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "关于我们",
          title_en: "About us",
          path: "aboutUs",
        },
        {
          title_zh: "使命 愿景 价值观",
          title_en: "Mission vision values",
          path: "",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
.outer {
  background: #1a2a60;
}

.outerOne {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 50%;
    height: 724px;
    overflow: hidden;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .boxTwo {
    background: #1a2a60;

    > div {
      padding: 190px 140px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 40%;
    }
    .tipOne {
      font-size: 30px;
      font-family: FZFengYaSongS-GB;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 100px;
    }

    .tipTwo {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
    }
  }

  .boxThree {
    background: #ffffff !important;
    display: flex;
    justify-content: flex-end;
    .tipOne,
    .tipTwo {
      color: #1a2a60 !important;
    }
  }
}

.outerTwo {
  padding: 175px 310px 199px;

  .tipFive {
    font-size: 30px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 101px;
    text-align: center;
  }

  .imgList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      width: 265px;
      height: 309px;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>